
.formerrorstyle {
    color: @red;
    font-weight: bold;
}

.help-inline,
.help-block {
    color: @helptextcolor;
}

input:focus + .help-inline,
input:focus + .help-block {
    color: @helptextcolor_active;
    font-weight: bold;
}

form.fForm {
    .error { color: @red; }

    .fDescr {}

    fieldset {}

    .form-error {
        color: white;
        background-color: @red;
        font-weight: bold;
        text-align: left;
        padding: 8px;
        margin: -5px 0px 15px;
    }

    &>table, table.fTable {

        tbody {
            th {
                label {
                    line-height: 17px !important;
                    display:block;
                    margin-bottom: 0;
                }

                vertical-align: baseline;
                padding-right: 0.5ex;
            }
            td {
                input { margin-bottom: 0; }
                line-height: 17px;
                text-align: left;
                vertical-align: baseline;
            }

        }

        tr {
            th {
            //color: ;
                text-align: right;
                font-weight: normal;
            }

            td {
                span {
                    margin: 0 0 10px;
                    font: 10px/10px Verdana, "Luxi Sans", sans-serif;
                }
            }

            div.error { .formerrorstyle }

        }

        tr.required th,
        label.required { font-weight: bold !important; }

        tr.error {
            span {
                clear: left;
                .formerrorstyle;
            }
            input:not(.submit) {
                background-color: lighten(@red, 45%);
                border: thin solid red;
            }
        }

        tr.disabled {
            th {}
            td {}
            input {}
            select {}
            span { color: #a9a9a9; visibility: hidden; }
        }



        input.submit, button.submit {
            margin-top: 12px;
        }

        .hRadioWidget {
            // padding-left: 1ex;
            display: inline;

            label {
                vertical-align: middle;
                font: 12px/12px verdana;
                margin-right: 1.2ex;
            }
            input {
                //margin-right: 0.5ex;
            }
        }

        .vRadioWidget {
            tr {
                visibility: baseline;

                td {
                    label { font-size: 24px; }
                }
            }
        }
    }

    /* certain input types have special formatting */
    .vFileInput {}
    .vImageInput {}
    .vTextInput {width:204px;}
    .vTextArea {}
    .vPasswordInput {}
    .vPostnummerInput {width:3em;}
    .vBooleanInput {}
    .vRegexInput {}
    .vEmailInput {width:20em;}
    .vUrlInput {}
    input.submit { }
    .vBfnr {width:4em}
    .vKidNumber {width:6em}
    .vTelefonInput {width:13ex; }
    .vDecimalInput { width:30em; }
    th.hvTextArea { vertical-align:top; }
}

div.required {
  label {
    font-weight: bolder;
  }
  label:after {
    content: "*";
  }
}
div.vManyToManyTreeWidget {
  label.checkbox {
    font-weight: normal;
  }
  label:after {
    content: '';
  }
}

form.inline { margin:0; padding: 0; }
