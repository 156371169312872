/* -*- mode:css; coding: utf-8; -*- */

/* Styling for the hcard microformat (http://microformats.org/wiki/hcard)
*/

.vcard {
    .tel { white-space: nowrap; }
    .address {
        .street-address { white-space: nowrap; }
        .post-office-box { white-space: nowrap; }
    }
}