
// numerical report (i.e. headings top/left, numerical data in values).
table.numrep {
    margin-top:20px;
    margin-bottom: 20px;
    
    thead {
	th {
	    text-align: center !important;
	}
    }
    tbody {
	td {
	    text-align: right !important;
	    vertical-align: middle !important;
	}
    }
    caption { 
	caption-side: bottom; 
	text-align: right; 
	font-style: oblique; 
	font-size: 85%; 
    }
}
