@import "sol-layout";
@import "sol-theme";
@import "footer";
@import "homepage";

.login-card {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

form.fForm .vTextInput {
  width: 204px;
}
