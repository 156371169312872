/* -*- mode:css; coding: utf-8; -*- */

html, body {
  height: 100%;
}

.section {
}

.row.centered {
  margin-top: 0;
  margin-bottom: 0;
}

form.inline {
  display: inline;
}

.action-icon {
  display: inline-block;
}

.errordescr, .error {
  color: @errorcolor;
}

//.row > div:hover { outline: 4px solid lightblue; }
.page-break {
  display: none;
}

.row.centered {
  margin-top: 50px;
  margin-bottom: 50px;
}

.bordered-box {
  border-radius: @baseBorderRadius;
  padding: @paddingLarge;
  border: 1px solid #666;
  box-sizing: border-box;
}

.color-scheme {
  div {
    color: white;
  }

  @media print {
    display: none;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}


.navbar-static-top i {
  font-size: 14px;
  line-height: 13px;
  color: #a03033
}

.dropdown-menu li > a:hover i, .dropdown-menu li > a:focus i, .dropdown-submenu:hover > a i {
  color: white;
}


.brand {
  xpadding-left: 60px;
  position: relative;
  color: #a03033;
}

.brand > img {
  position: absolute;
  top: 4px;
  left: -20px;
  display: none;
}

.logout-button {
  margin-left: 20px;
}

ul.nav .btn-mini {
  top: 3px;
}

ul.nav .btn-small {
  top: 1px;
}


@noteAttrLineHeight: 13px;
@noteBorderWidth: 1px;

.notes {
  h4 {
    margin-bottom: 1.5ex;
  }

  .note {
    & + .note {
      margin-top: 20px;
    }

    background-color: lightyellow;
    border: @noteBorderWidth solid #999;
    position: relative;
    padding: 2ex 1ex;
    .border-radius(@baseBorderRadius);

    .author, .timestamp, .tags {
      position: absolute;
      background-color: lightyellow;
      padding: 0px 9px;
      border: @noteBorderWidth solid #999;
      line-height: @noteAttrLineHeight;


    }

    .timestamp, .author {
      top: -(@noteAttrLineHeight + @noteBorderWidth);
      border-bottom: none;
      border-radius: 2*@baseBorderRadius 2*@baseBorderRadius 0 0;
    }

    .author {
      right: 1ex;
    }

    .tags {
      right: 100px;
      bottom: -(@noteAttrLineHeight + @noteBorderWidth);
      border-top: none;
      border-radius: 0 0 2*@baseBorderRadius 2*@baseBorderRadius;
    }
  }
}

#logo {
}

// main menu + search
#header {
  //.gradient;
  nav {
    //border: 1px solid #eee;
    //padding: 14px;
  }

  #submenubar {
    display: none;
  }

  #menubar {
    a {
      color: white;
    }

    ul {
      margin: 0;

      li {
        float: left;
        padding: 0;
        list-style: none;

        a {
          text-transform: uppercase;
          padding: 10px 17px;
          margin-left: 5px;
        }
      }

      li.selected {
        margin: 7px 0 0 15px;
      }
    }
  }
}


#site_content {
  overflow: visible;
  clear: both;
}


#main {
  box-sizing: border-box;
  //    padding-top: 41px;

  //min-height: 100%;
  //min-height: -webkit-calc(~"100% - 180px");  // subtract top nav-bar and margins.
  //min-height:    -moz-calc(~"100% - 180px"); // subtract top nav-bar and margins.
  //min-height:         calc(~"100% - 180px"); // subtract top nav-bar and margins.
  margin-bottom: @footerHeight;
}

.lt-ie9 {
  footer {
    margin-top: 0;
  }

  #main {
    min-height: 0;
    height: 0;
    margin-bottom: 0;
    //        margin-bottom: -41px !important;
  }

  //    position: absolute !important;
  //    bottom: 0; left:0; right: 0;
}

.result {
}

.result a.link {
  color: #1122CC;
  display: block;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
}

.result > h3 {
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 2ex;
}

.result > cite {
  color: #BBBBBB;
  font-size: 92%;
}

.teaser-content {
  .teaser-img {
    vertical-align: text-top;

  }
}

.button-grid {
  // margin-bottom: @gridGutterWidth;
  margin-bottom: 20px;
  overflow: hidden;
}

.button-grid a {
  text-decoration: none;
}

.button-cell {
  text-align: center;
  margin: 5px;
  width: 120px;
  height: 120px !important;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 120%;
}

/* set these as a multiple of base width after moving this code to the less file. */
.button-cell.double-width {
  width: 240px;
}

.button-cell.triple-width {
  width: 360px;
}

.button-cell img {
  xclear: left;
  xfloat: left;
  xdisplay: block;
  xmargin: 0 auto 10px;
  margin-right: 4px;
  border: 1px solid #ccc;
  .border-radius(@baseBorderRadius);
  background-color: #eee;
  padding: 14px;
}

.button-grp {
  margin-bottom: 15px;
}

.purchase-btn {
  padding-top: 1ex;
  width: 100%;
}

.btn {
  float: left;
  line-height: 120%;
  margin-bottom: 5px;
}

.double-btn {
  height: 100px;
}

.single-btn {
  height: 210px;
}

.purchase-btn > .price {
  font-size: smaller;
  display: block;
}

.purchase-btn > .price::before {
  content: "(";
}

.purchase-btn > .price::after {
  content: ")";
}

@media screen and (max-width: 480px) {
  footer .org {
    float: left !important;
    margin: 0 2ex;
  }

  footer .col2 {
    float: left !important;
    border-left: none !important;
    clear: both;
    margin: 1ex 2ex 0 8ex !important;
  }
}

@media screen and (max-width: 767px) {
  footer {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.homelogo {
  display: none;
}

.sublogo {
  display: block;
}

.logo img {
  margin-left: auto;
  margin-right: auto;
}

.homepage .logo img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.homepage .sublogo {
  display: none;
}

.homepage .homelogo {
  display: block;
}

.homepage .logo .logo-img-box {
  margin-left: 50px;
}

.logo .logo-img-box {
  margin-left: 10px;
}

.logo .logo-text-box {
  margin-left: 20px;
}

.logo div {
  display: inline-block;
  vertical-align: middle;
}

.homepage #mainlogo h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 96px;
  line-height: 50px;
  margin-bottom: 1ex;
  color: #f5dd48;
  letter-spacing: -1px;
  text-shadow: 0.1em 0.1em 0.2em black;
  font-weight: bold;
}

#mainlogo h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 32px;
  margin-bottom: 2ex;
  color: #f5dd48;
  text-shadow: 0.1em 0.1em 0.2em black;
  font-weight: bold;
}

.ximg-polaroid {
  float: right;
  margin-left: 8px;
  margin-bottom: 8px;
}

.thumbbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px;
  max-width: 300px;

  .thumbboxtxt {
    padding: 0 7px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.homepage .right-col {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
