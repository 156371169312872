footer.page-footer {
  box-sizing: border-box;
  position: relative;
  background-color: #000;
  //margin-top: 50px;
  //font-size: 12px;

  .footer-content {
    min-height: 8cm;
    display: flex;
    gap: 10%;
    flex-direction: row;
    justify-content: center!important; // required by Edge to center elements.
    align-items: center;
    font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
    letter-spacing: .2em;
    color: #fff !important;

  .footer-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;

  }
    @media screen and (max-width: 835px) {
      flex-direction: column;
      min-height: 12cm;

      .footer-menu {
        align-items: center;
        padding-bottom: 4rem;
        margin-top: -4rem;
      }
    }

    a, a:hover {
      color: #fff;
    }

    .footer-label {
      color: #b4b4b4
    }

    .norsktest-footer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @media screen and(max-width: 835px) {
        order: 2
      }

      .contact-info {
        padding: 0 1cm;
        font-variant: small-caps;
        font-size: 12px;
      }

      .created-by {
        font-weight: 700;
        line-height: 190%;
        text-align: left;
        border-left: 1px solid #b4b4b4;
        padding: .5cm 1cm;
        font-size: 12px;

        a {
          font-size: inherit;
        }

        @media screen and(max-width: 520px) {
          display: none
        }
      }

      .info-list {
        list-style-type: none
      }
    }

    .footer-menu a {
      display: block;
      font-size: 12px;
    }
  }

  .legal {
    position: absolute;
    left: 40px;
    bottom: 20px;

    a, footer.page-footer .legal a:hover {
      color: #b4b4b4;
      margin-right: 1em;
      font-size: 12px;
    }
  }
}
