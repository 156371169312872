

.datagrid {
    .filter-option > label { display: inline; }

    .ui-widget-header {
        background: url("http://static.datakortet.no/css/solarium/images/ui-bg_gloss-wave_75_dadada_500x100.png") repeat-x scroll 50% 50% #fbf8ee !important;
        border: 1px solid #fbf8ee !important;
    }
    .ui-widget-content { padding: 0 !important; }
    .filter-item-header {
        background: #fafafa !important;
        .filter-name {
            //color: white
        }
    }
    .ui-widget + .ui-widget { margin-top: 5px !important; }

    .result {
        .data {
            width: 100%;
        }
    }
}
