/* -*- mode:css; coding: utf-8; -*- */

/*
 *  General styling for printing.
 */
@media print {

    body {
        background-color: transparent;
        background-image: none;
        font-size: 10pt;
        lint-height: 200%;
        font-family: Georgia,serif;
        widows: 5;
        orphans: 5;
    }
    
    

    .social,
    iframe,
    #header,
    #cms_toolbar,
    .navbar-static-top,
    .navbar-fixed-top,
    #nt-sign,
    .no-print { display: none; }

    .page-break {
        display: block;
        page-break-before: always;
    }
    
    #main {
	min-height: 0;
	margin-bottom: 1.2cm;
	
	a {
	    color: blue;
	    text-decoration: underline;
	    
	    &:after {
		content: " (" attr(href) ") ";
		font-size: 85%;
	    }
	    
	    &[href^="/"]:after {
		content: " (http://www.solarieprøven.no" attr(href) ") ";
	    }
	}
    }

    #main-content {
        page-break-after: auto;
    }

    .story {
        margin-top: 1cm;
        margin-bottom: 1cm;
        margin-left: 1cm;
        width: 80%;
        font-size: 92%;
    }

    .ui-widget {
        border: none;

        .ui-widget-header {
            border: none;
            color: #000000;
            margin-bottom: 1.5ex;
        }

        .ui-widget-content {
            border: none;
        }
    }

    


    footer {
	position: absolute; bottom:0; 
	border-top: 1px solid #ddd; 
	height: auto;
	padding: 0;
	font-size: 7pt !important;

	margin: 0 auto;

	a { text-decoration: none; }
	a:after { content: none; }
	&[href^="/"]:after { content: none; }
	.sep { color: lighten(black, 35%); }
	
	.footer-content {
	    padding-top: 0;
	}
    }

}
