/* -*- mode:css; coding: utf-8; -*- */

/*
 *   separate file for navigation / main menu layout
 *
 *   Expected markup:

     <div class="row main-menu-row">
       <div class="span12">
         <ul class="breadcrumb">..</ul>

         <div class="hovedmeny">
           <li>
             hovedmeny
             <b class="icon-chevron-down"></b>
           </li>
         </ul>
       </div>
     </div>

 */

.row + .main-menu-row {
    margin-top: @spacing;
}

.main-menu-row {

    margin-bottom: @spacing;

    .main-menu-bbox {
        position:relative;
        background: @basecolor;
        color: white;
        height: 30px;
        width: 100%;

        .main-menu-button {
            width: 110px;
            text-align: center;
            cursor: pointer;
            .container-fixed();

            .main-menu-title {
                position: absolute;
                bottom: @spacing;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                margin: 0;
            }
            .menu-open-icon {}
        }
    }
}

// #mainmenu is the pull down menu..

//#mainmenu {
//    position: absolute;
//    background-color: #a03033;
//    border-left: 1px solid #ccc;
//    color: white;
//    z-index: 1000;
//    opacity: 0.98;
//
//    a {
//        color: white;
//    }
//}
//
//nav.hovedmeny > ul > li {
//    display: inline-block;
//    vertical-align: top;
//    padding: 20px;
//}
//
//nav.hovedmeny > ul > li > a {
//    border-bottom: 1px solid white;
//}
//
////.dropdown-menu a:hover [clss^=icon] { color: white; }
//
//nav > .nav {
//    margin-bottom: 35px;
//    position: relative;
//}
//
//.nav .ancestor {
//    border-radius: 4px;
//    -moz-border-bottom-colors: none;
//    -moz-border-left-colors: none;
//    -moz-border-right-colors: none;
//    -moz-border-top-colors: none;
//    background-color: #FFFFFF;
//    border-color: #DDDDDD #DDDDDD transparent;
//    border-image: none;
//    border-style: solid;
//    border-width: 1px;
//    color: #555555;
//    cursor: default;
//}
//
//nav .nav-pills {
//    position: absolute;
//    top: 40px;
//    xleft: 7px;
//    font-size: 92%
//}
//
//nav .nav-pills li > a {
//    padding: 4px 8px;
//}
