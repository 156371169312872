
.demographics .name {
  font-size: 18px;
}

section > h1 {
  font-size: 17px;
  margin-top: 0;
}

.demographics {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .demographics {
    flex-direction: column;
  }
}


.personinfo {
    .item-group {
        margin-top: 15px;
    }
    .item {
        clear: both;
        min-height: 1px;
        border-bottom: 1px dotted #999;
        overflow: hidden;
    }

    .panel-heading .item {
        border-bottom: none;
    }

    .item {
        margin-top: 3px;
    }

    .key {
        float: right;
        font-size: 85%;
        font-style: italic;
        color: #999;
        padding-right: 1ex;
    }
    .value {
    }

    .panel > .key,
    .panel > .value {
        float: none;
        width: 100%;
        max-width: 100%;
    }

    .panel > .key::after {
        content: none;
    }
}


section.person {
  header {
    font-size: x-large;
    border-bottom: 1px solid #666;
    padding-bottom: @gridGutterWidth / 2;
    margin-bottom: @gridGutterWidth / 2;
  }

  .employment-data {
    margin-bottom: @gridGutterWidth / 2;

    .persnr {
      word-spacing: -2px;
      float: right;
    }
  }

  .contact-info {
    margin: @gridGutterWidth / 2;


  }

  .button.admin {
    font-size: 85%;
    line-height: 100%;
    border-radius: 5px;

    font-weight: bold;
    cursor: pointer;
    background: #D62A2A;
    color: white;
    border: 2px solid red;

    &:hover {
      box-shadow: yellow;
      color: yellow;
    }
  }
}

.results {
  padding-top: 20px;
}

.candidate-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.profile-person {
    [admin] { outline: none; }
}
.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.actionarea {
    margin-top: 85px;
}
.calendar-row {
    padding-right: 15px;
}
