/* -*- mode:css; coding: utf-8; -*- */

.ui-widget + .ui-widget { margin-top: @gridGutterWidth; }

.ui-widget {
    border-color: @c1;

    .ui-widget-header {
        padding: 3px 8px;
        background: @bordercolor;
        border-color: @bordercolor;
    }
    .ui-widget-content {
        padding: @gridGutterWidth;
        border-top: none;
    }
}

.ui-widget.datagrid {
    .ui-widget-header {
        background-color: inherit !important;
    }
}
