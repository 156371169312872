/* -*- mode:css; coding: utf-8; -*- */
/*
   Theme type styling.
   
   All colors etc. should be defined here and included.
*/


// color palette
@basecolor: #aaaaaa;  // grey

@c1: #95a3ab;
@c2: #9b5f0e;
@c3: #652300;
@red: #a03033;

@bordercolor: #dddddd;
@errorcolor: @red;
@helptextcolor: #bbb;
@helptextcolor_active: #666;

// e.g. menu bar
//.gradient {
//    #gradient > .vertical(@basecolor, darken(@basecolor, 15%));
//}

// e.g. footer
//.gradient-secondary {
//    #gradient > .vertical(@basecolor, darken(@basecolor, 7%));
//}
.border-radius(@radius) { border-radius: @radius }
.base-border-radius { .border-radius(@baseBorderRadius); }
.border-radius-large { .border-radius(@borderRadiusLarge); }
.border-radius-small { .border-radius(@borderRadiusSmall); }

.padding-large { padding: @paddingLarge; }
.padding-small { padding: @paddingSmall; }
.padding-mini { padding: @paddingMini; }

// special formatting
table.table-bordered {
    thead {
        th { background-color: @c1; color: white; }
    }
}

.datagrid {
    .pagesize-selector {
        select {
            margin-bottom: 0;
            width: auto;
            padding: 0;
            height: 20px;
            line-height: 20px;
        }
    }
}

// bootstrap overrides
a:focus { outline: none; }


// layout: spacing

@vertical_space: @gridGutterWidth;

.row + .row { margin-top: @vertical_space; }
.no-vertical-space,
.no-vertical-space + .row { margin-top: 0 !important; }



// debugging utilities

.blue { background-color: @blue; }
.green { background-color: @green; }
.red { background-color: @red; }
.yellow { background-color: @yellow; }
.orange { background-color: @orange; }
.pink { background-color: @pink; }
.purple { background-color: @purple; }



.basecolor { background-color: @basecolor; }
.textcolor { background-color: @textColor; }
.bordercolor { background-color: @bordercolor; }
.c1 { background-color: @c1; }
.c2 { background-color: @c2; }
.c3 { background-color: @c3; }

@h1Size: @baseFontSize * 2.285714286;
@h2Size: @baseFontSize * 1.5;
@h3Size: @baseFontSize * 1.214285714;
@h4Size: @baseFontSize * 1.071428571;
@h5Size: @baseFontSize;
@h6Size: @baseFontSize * 0.714285714;

h1 { font-size: @h1Size } // =32
h2 { font-size: @h2Size }         // =21
h3 { font-size: @h3Size } // =17
h4 { font-size: @h4Size } // =15
h5 { font-size: @h5Size }
h6 { font-size: @h6Size } // =10

.interaction_box {
  //#gradient > .directional(#F9F9F9, darken(#F9F9F9, 5%), 150deg);
  border: 1px solid #DDDDDD;
  border-radius: 4px 4px 4px 4px;
  padding: 11px 19px;
  box-shadow: 0 8px 3px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
.dk-widget {
  box-shadow: 0 8px 3px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
header.dk-widget-header {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin: 0 -19px;
  margin-bottom: 10px;
  padding: 0 19px;
  text-transform:uppercase;
  color: #999999;
  font-size: 11.9px;
}
.afrsearch .info_text {
  font-size: 17.5px;
  line-height: 140%;
  margin-bottom: 0.5ex;
}
.info-box > h4 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

@footerHeight: 180px;
@Phi: 1.618;

.unbreakable { white-space: nowrap; }
article.teaser header > h1 {
  font-size: @h2Size + 2px;
}
.homepage article.teaser header > h1 {
  font-size: @h1Size;
}

.plugin_link {
  float: left;
  width: 100%;
}

p .plugin_link {
  float: none;
}
.center {
    text-align:center;
}
.tab-content {
  margin-bottom: 10px;
}


@sol-basecolor-1: #337ab7;
@sol-basecolor-2: #2e6da4;
@sol-basecolor-3: #5cb85c;
@theme-lightgreen: #B4EEB4; // green'ish
@theme-extra-mandarin: #DB3F21; // red'ish
@theme-orange: #EFA07A; // lighter than red'ish
@theme-lightblue: #5bc2e7; // light blue'ish
@theme-blue: #3057A5;

@ok-color: @theme-lightgreen;
@danger-color: @theme-extra-mandarin;
@error-color: @theme-extra-mandarin;
@warning-color: @theme-orange;
@info-color: @theme-lightblue;

.modal-page {

  &.ok {
    background-color: @ok-color;
  }

  &.danger {
    background-color: @danger-color;
  }

  &.error {
    background-color: @error-color;
  }

  &.warning {
    background-color: @warning-color;
  }

  &.info {
    background-color: @sol-basecolor-2;
  }

  .modal-box {
    margin-top: 5%;
    max-width: 800px;
    border: 1px solid #999;
    border-radius: 6px;
    padding: 60px;
    min-height: 400px!important;
    background-color: white;

    > header {
      border-bottom: 1px solid #cccccc;
      padding-bottom: 15px;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
  }
}
