.card-page {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

:root {
    --qr-size: 280px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.phone {
    position: relative;

    .phone-content {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        border: 2px solid #00205b;

        @media screen and (max-width: 600px) {
          border: none;
        }

        hgroup {
            display: flex;
            align-items: center;
            max-width: 100vw;

            .header {
                display: flex;
                align-items: center;
                gap: 1rem;
                justify-content: center;
                padding: 2rem;
                img {
                    width: 90%;
                }
            }

            h1 {
                font-size: clamp(1rem, 6vw, 2rem);
                margin: 0;
            }


        }
        hgroup:hover {
            background-color: #00205bbe;
        }

        .qr-wrapper {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                text-align: center;
                font-size: .8rem;
                margin-top: 0;
            }
        }

        em {
            font-style: normal;
            font-size: 120%;
            margin-inline: 1rem;
            font-weight: bolder;
        }

        .content {
            text-align: center;
            padding-inline: 2rem;
            margin-bottom: 20px;
            h2 {
                margin-bottom: 0;
            }

            p.fdato {
                margin: 0;
                color: #666;
            }
        }

        .valid {
            color: green;
            font-size: 1.5rem;
            text-align: center;

        }

        .photo {
            max-width: 100%;
          max-height:200px;
          margin-top: 20px;
        }

        footer {
            padding-block: clamp(5px, 3vw, 2rem) clamp(5px, 5vw, 2rem);
            padding-inline: clamp(5px, 5vw, 2rem);
            color: black;
            position: relative;
            height: 180px;
            clear: both;
            font-size: 12px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.2em;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
